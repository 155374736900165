import { catchError, from, map, Observable, tap } from "rxjs";
import { deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { usersStore } from "./users.store";
import { GETUsers, User, UserCard, UserFilter } from "./user.model";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from "../../utils/errors.utils";
import { AxiosError, AxiosResponse } from "axios";
import { getUsersDataSource } from "./users.requests";
import { PaginationDto } from "@utils/pagination.dto";

export class UsersServices {
  store = usersStore;

  resetStore = () => this.store.reset();

  setPagination = (pagination: PaginationDto) =>
    this.store.update((state) => ({
      ...state,
      pagination,
    }));

  setFilters = (filters: Partial<UserFilter>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getCompanyUsers = (filters: UserFilter): Observable<GETUsers> => {
    console.log("filters are", filters);

    return from(APIAxios({ ...APIRoutes.GETCompanyUsers(filters) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<GETUsers>) => {
        return response.data;
      }),
      tap((users) => {
        this.store.update(setEntities(users.users), getUsersDataSource.setSuccess());
        this.setPagination(users);
      })
    );
  };

  getUsers = (filters: UserFilter): Observable<GETUsers> => {
    return from(
      APIAxios({
        ...APIRoutes.GETUsers(
          filters.page,
          filters.orderBy,
          filters.orderByDirection || "asc",
          filters.searchText,
          filters.noPagination
        ),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<GETUsers>) => {
        return response.data;
      }),
      tap((users) => {
        this.store.update(
          setEntities(
            users.users.map((user) => {
              if (!user.lastName) user.lastName = "";
              if (!user.firstName) user.firstName = "";
              return user;
            })
          ),
          getUsersDataSource.setSuccess()
        );
        this.setPagination(users);
      }),
      getUsersDataSource.trackRequestStatus()
    );
  };

  addRemoveCardFromGroup = (groupId: string, card: UserCard) => {
    return from(APIAxios({ ...APIRoutes.ToggleCardFromGroup(groupId, card.cardId) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<any>) => {
        return response.data;
      }),
      tap((responseCard) => {
        this.store.update(updateEntities(responseCard.user.id, responseCard.user));
      })
    );
  };

  removeGroup = (groupId: string): Observable<User> => {
    return from(APIAxios({ ...APIRoutes.DELETEGroup(groupId) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<User>) => {
        return response.data;
      }),
      tap((user) => {
        this.store.update(updateEntities(user.id, user));
      })
    );
  };

  deleteUser = (userId: string): Observable<User> => {
    return from(APIAxios({ ...APIRoutes.DELETEUser(userId) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<User>) => {
        return response.data;
      }),
      tap(() => {
        this.store.update(deleteEntities(userId));
      })
    );
  };
}

export const usersServices = new UsersServices();
