import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

export interface SelectItem {
  label: string;
  value: any;
  data?: any;
}
interface BasicSelectProps {
  color?: "primary" | "secondary";
  fullWidth?: boolean;
  placeholder?: string;
  value?: any;
  stringValue?: string;
  readOnly?: boolean;
  error?: string;
  ariaLabel?: string;
  items?: SelectItem[];
  handleChange?: (value: any) => void;
  maxWidth?: string;
  canBeEmpty?: boolean;
  noValueText?: string;
}

const BasicSelect = (props: BasicSelectProps) => {
  const {
    color,
    fullWidth,
    canBeEmpty = false,
    error,
    placeholder,
    items,
    readOnly,
    value,
    handleChange,
    maxWidth,
    noValueText = "Aucun choix",
  } = props;
  const handleSelectChange = (selectedValue: string) => {
    if (selectedValue !== "" || selectedValue !== placeholder) {
      handleChange?.(selectedValue);
    }
  };

  return (
    <FormControl fullWidth>
      {!!placeholder && (
        <InputLabel
          id={placeholder}
          sx={{
            "& +.MuiInputBase-root": {
              marginTop: 0,
            },
            "&.MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.87)",
              position: "absolute",
              top: "-10px",
              left: "0px",
            },
          }}
        >
          {placeholder}
        </InputLabel>
      )}
      <Select
        labelId={placeholder}
        disabled={readOnly}
        value={value}
        size="small"
        fullWidth={fullWidth}
        error={!!error}
        color={color}
        variant="standard"
        disableUnderline
        displayEmpty
        MenuProps={{
          style: { zIndex: 2001 },
        }}
        sx={{ maxWidth }}
        onChange={(evt) => handleSelectChange?.(evt.target.value)}
      >
        {!!noValueText && (
          <MenuItem disabled={!canBeEmpty} value="" style={{ opacity: 0.5 }}>
            {noValueText}
          </MenuItem>
        )}
        {items?.map((item) => (
          <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BasicSelect;
