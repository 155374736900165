import { Merchant } from "../payments";
import { Category } from "../serviceCategories";
import { Colors } from "../../constants/colors.constants";
import I18n from "../../utils/i18n.utils";

export enum CardStatus {
  ConsentPending = "ConsentPending",
  Processing = "Processing",
  Enabled = "Enabled",
  Suspended = "Suspended",
  Pending = "Pending",
  Canceled = "Canceled",
  Canceling = "Canceling",
  Declined = "Declined",
  Activated = "Activated",
  ToActivate = "ToActivate",
}

export enum CardType {
  Virtual = "Virtual",
  VirtualAndPhysical = "VirtualAndPhysical",
  SingleUseVirtual = "SingleUseVirtual",
}

export interface Card {
  id: string;
  name: string;
  status: CardStatus;
  type: CardType;
  group: string;
}

export interface Transaction {
  timeout: number;
  transactionId: string;
  paymentId: string;
  cardId: string;
  dateTime: number;
  originalAmountValue: number;
  amountValue: number;
  merchantId: string;
  merchantCategoryCode: string;
  merchantName: string;
  merchantCity: string;
  merchantCountry: string;
  transactionCategory: string;
  authorizationType: string;
}

export interface TransactionFilter {
  status?: TransactionStatus;
  search?: string;
  userId?: string;
  swanStatusInfo?: SwanStatusInfo;
  isAmountMatch?: boolean;
  noProof?: boolean;
}

export interface TransactionLight {
  id: string;
  status: TransactionStatus;
  date: string;
  card: Card | null;
  dateOfService: string;
  employee: UserLight;
  serviceCategory?: Category;
  service: string;
  amount: number;
  merchant: Merchant;
  city: string;
  country: string;
  taxes: Tax[];
  swanStatusInfo?: SwanStatusInfo;
  swanTransactionSide?: SwanTransactionSide;
  isAmountMatch?: boolean;
  noProof?: boolean;
}

export enum SwanTransactionSide {
  DEBIT = "Debit",
  CREDIT = "Credit",
}

export interface Tax {
  amount: number;
  percent: number;
}

export enum TransactionStatus {
  ACTION_REQUIRED = "ACTION_REQUIRED",
  OK = "OK",
  REQUESTED = "REQUESTED",
  PENDING = "PENDING",
  DENIED = "DENIED",
  FAILED = "FAILED",
}

export interface TransactionTotal {
  allTotal: string;
  allQuantity: number;
  OKTotal: string;
  OKQuantity: number;
  actionRequiredTotal: string;
  actionRequiredQuantity: number;
  pendingTotal: string;
  pendingQuantity: number;
  failedTotal: string;
  failedQuantity: number;
  isAmountMatchFalseTotal: string;
  isAmountMatchQuantity: number;
  noProofTotal: string;
  noProofQuantity: number;
}

export namespace TransactionStatus {
  export const status: TransactionStatus[] = [
    TransactionStatus.DENIED,
    TransactionStatus.OK,
    TransactionStatus.REQUESTED,
    TransactionStatus.ACTION_REQUIRED,
    TransactionStatus.FAILED,
  ];

  export const color = (status: TransactionStatus): string => {
    switch (status) {
      case TransactionStatus.OK:
        return Colors.green;
      case TransactionStatus.REQUESTED:
        return Colors.yellow;
      case TransactionStatus.ACTION_REQUIRED:
        return Colors.yellow;
      case TransactionStatus.DENIED:
        return Colors.warning;
      case TransactionStatus.PENDING:
        return Colors.skyblue;
      case TransactionStatus.FAILED:
        return Colors.warning;
    }
  };

  export const label = (status: TransactionStatus): string => {
    switch (status) {
      case TransactionStatus.OK:
        return I18n.t("OK");
      case TransactionStatus.REQUESTED:
        return I18n.t("payments.toBeCompleted");
      case TransactionStatus.DENIED:
        return I18n.t("payments.refused");
      case TransactionStatus.PENDING:
        return I18n.t("payments.pending");
      case TransactionStatus.ACTION_REQUIRED:
        return I18n.t("payments.actionRequired");
      case TransactionStatus.FAILED:
        return I18n.t("payments.failed");
    }
  };
}

export interface UserLight {
  id: string;
  lastName: string;
  firstName: string;
}

export function formatTransactionFullToTransactionLight(body: any, transaction: TransactionFull): TransactionLight {
  return {
    id: transaction.id,
    status: transaction.status,
    card: transaction.card,
    date: transaction.date,
    dateOfService: transaction.dateOfService,
    employee: { id: body.user.id, lastName: body.user.lastName, firstName: body.user.firstName },
    serviceCategory: transaction?.serviceCategory ?? undefined,
    service: "", // USELESS
    amount: transaction.amount,
    merchant: { name: transaction.merchantName, merchantId: transaction.mid },
    city: transaction.merchantCity,
    country: transaction.merchantCountry,
    taxes: transaction.taxes,
    swanTransactionSide: transaction.swanTransactionSide,
  };
}

export interface TransactionFull {
  id: string;
  amount: number;
  currency: string;
  originalAmount: number;
  originalCurrency: string;
  status: TransactionStatus;
  noProof: boolean;
  card: Card | null;
  date: string;
  dateBooked: string;
  dateOfService: string;
  user: UserLight;
  paymentId: string;
  transactionId: string;
  expenseType: ExpenseType;
  type: TransactionType;
  serviceCategory?: Category;
  reference: string;
  carbonFootprintEstimate?: number;
  distanceKmEstimate?: number;
  vehicleKilometersAtTransaction?: number;
  quantity?: number;
  reason?: string;
  refuseReason?: string;
  swanStatusInfo: SwanStatusInfo;
  rejectedReasonCode: string;
  isAmountMatch: boolean;
  merchantName: string;
  merchantCity: string;
  merchantCountry: string;
  mid: string;
  mcc: string;
  mccDescription: string;
  swanTransactionSide: SwanTransactionSide;
  taxes: Tax[];
  transactionFailedReason: TransactionFailedReasonEnum;
  isUpdatable: boolean;
  createdAt: Date;
  invoice?: string;
  updatedAt?: Date;
  updatedBy?: UserLight;
  isRoundTrip?: boolean;
  fromLocation?: string;
  toLocation?: string;
  fuelType?: string;
  fuelVolumeLiters?: string;
}

export interface ExportTransactionFilters {
  status: TransactionStatus | undefined;
  //type: TransactionType;
  from?: string;
  to?: string;
  isUpdatable?: boolean | undefined;
}

export interface ExportTransaction {
  email: number;
  firstName: number;
  lastName: number;
  dateOfService: number;
  date: number;
  dateBooked: number;
  status: number;
  refuseReason: number;
  transactionFailedReason: number;
  amount: number;
  amountBeforeTaxes: number;
  amountOfTaxes: number;
  isAmountMatch: number;
  type: number;
  serviceType: number;
  merchantName: number;
  merchantCity: number;
  merchantCountry: number;
  mid: number;
  categoryName: number;
  mcc: number;
  mccDescription: number;
  expenseType: number;
  isRoundTrip: number;
  distanceKmEstimate: number;
  vehicleKilometersAtTransaction: number;
  quantity: number;
  reason: number;
  invoice: number;
  carbonFootprintEstimate: number;
  updatedBy: number;
  updatedAt: number;
  isUpdatable: number;
  paymentId: number;
  transactionId: number;
  swanTransactionSide: number;
  categoryAccount: number;
  card: number;
  timeOfPayment: number;
}

export const ExportTransactionFieldsInit = {
  email: -1,
  firstName: -1,
  lastName: -1,
  dateOfService: -1,
  date: -1,
  dateBooked: -1,
  status: -1,
  refuseReason: -1,
  transactionFailedReason: -1,
  amount: -1,
  amountBeforeTaxes: -1,
  amountOfTaxes: -1,
  isAmountMatch: -1,
  type: -1,
  serviceType: -1,
  merchantName: -1,
  merchantCity: -1,
  merchantCountry: -1,
  mid: -1,
  categoryName: -1,
  mcc: -1,
  mccDescription: -1,
  expenseType: -1,
  isRoundTrip: -1,
  distanceKmEstimate: -1,
  vehicleKilometersAtTransaction: -1,
  quantity: -1,
  reason: -1,
  invoice: -1,
  carbonFootprintEstimate: -1,
  updatedBy: -1,
  updatedAt: -1,
  isUpdatable: -1,
  paymentId: -1,
  transactionId: -1,
  swanTransactionSide: -1,
  categoryAccount: -1,
  card: -1,
  timeOfPayment: -1,
  noProof: -1,
  cardGroup: -1,
  fuelType: -1,
  fuelVolumeLiters: -1,
};

export enum TransactionFields {
  EMAIL = "email",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  DATE_OF_SERVICE = "dateOfService",
  DATE = "date",
  DATE_BOOKED = "dateBooked",
  STATUS = "status",
  REFUSE_REASON = "refuseReason",
  TRANSACTION_FAILED_REASON = "transactionFailedReason",
  AMOUNT = "amount",
  AMOUNT_BEFORE_TAXES = "amountBeforeTaxes",
  AMOUNT_OF_TAXES = "amountOfTaxes",
  IS_AMOUNT_MATCH = "isAmountMatch",
  TYPE = "type",
  SERVICE_TYPE = "serviceType",
  MERCHANT_NAME = "merchantName",
  MERCHANT_CITY = "merchantCity",
  MERCHANT_COUNTRY = "merchantCountry",
  MID = "mid",
  CATEGORY_NAME = "categoryName",
  MCC = "mcc",
  MCC_DESCRIPTION = "mccDescription",
  EXPENSE_TYPE = "expenseType",
  IS_ROUND_TRIP = "isRoundTrip",
  DISTANCE_KM_ESTIMATE = "distanceKmEstimate",
  VEHICLE_KILOMETERS_AT_TRANSACTION = "vehicleKilometersAtTransaction",
  QUANTITY = "quantity",
  REASON = "reason",
  INVOICE = "invoice",
  CARBON_FOOTPRINT_ESTIMATE = "carbonFootprintEstimate",
  UPDATED_BY = "updatedBy",
  UPDATED_AT = "updatedAt",
  IS_UPDATABLE = "isUpdatable",
  PAYMENT_ID = "paymentId",
  TRANSACTION_ID = "transactionId",
  SWAN_TRANSACTION_SIDE = "swanTransactionSide",
  CATEGORY_ACCOUNT = "categoryAccount",
  CARD = "card",
  TIME_OF_PAYMENT = "timeOfPayment",
  NOPROOF = "noProof",
  CARDGROUP = "cardGroup",
  FUEL_TYPE = "fuelType",
  FUEL_VOLUME_LITERS = "fuelVolumeLiters",
}

export namespace TransactionFields {
  export const fields: TransactionFields[] = [
    TransactionFields.EMAIL,
    TransactionFields.FIRST_NAME,
    TransactionFields.LAST_NAME,
    TransactionFields.DATE_OF_SERVICE,
    TransactionFields.DATE,
    TransactionFields.DATE_BOOKED,
    TransactionFields.STATUS,
    TransactionFields.REFUSE_REASON,
    TransactionFields.TRANSACTION_FAILED_REASON,
    TransactionFields.AMOUNT,
    TransactionFields.AMOUNT_BEFORE_TAXES,
    TransactionFields.AMOUNT_OF_TAXES,
    TransactionFields.IS_AMOUNT_MATCH,
    TransactionFields.TYPE,
    TransactionFields.SERVICE_TYPE,
    TransactionFields.MERCHANT_NAME,
    TransactionFields.MERCHANT_CITY,
    TransactionFields.MERCHANT_COUNTRY,
    TransactionFields.MID,
    TransactionFields.NOPROOF,
    TransactionFields.CATEGORY_NAME,
    TransactionFields.MCC,
    TransactionFields.MCC_DESCRIPTION,
    TransactionFields.EXPENSE_TYPE,
    TransactionFields.IS_ROUND_TRIP,
    TransactionFields.DISTANCE_KM_ESTIMATE,
    TransactionFields.VEHICLE_KILOMETERS_AT_TRANSACTION,
    TransactionFields.QUANTITY,
    TransactionFields.REASON,
    TransactionFields.INVOICE,
    TransactionFields.CARBON_FOOTPRINT_ESTIMATE,
    TransactionFields.UPDATED_BY,
    TransactionFields.UPDATED_AT,
    TransactionFields.IS_UPDATABLE,
    TransactionFields.PAYMENT_ID,
    TransactionFields.TRANSACTION_ID,
    TransactionFields.SWAN_TRANSACTION_SIDE,
    TransactionFields.CATEGORY_ACCOUNT,
    TransactionFields.CARD,
    TransactionFields.TIME_OF_PAYMENT,
    TransactionFields.CARDGROUP,
    TransactionFields.FUEL_TYPE,
    TransactionFields.FUEL_VOLUME_LITERS,
  ];

  export const label = (field: TransactionFields): string => {
    switch (field) {
      case TransactionFields.EMAIL:
        return "Adresse mail";
      case TransactionFields.FIRST_NAME:
        return "Prénom";
      case TransactionFields.LAST_NAME:
        return "Nom de famille";
      case TransactionFields.DATE_OF_SERVICE:
        return "Date de la prestation";
      case TransactionFields.DATE:
        return "Date du paiement";
      case TransactionFields.DATE_BOOKED:
        return "Date du règlement";
      case TransactionFields.STATUS:
        return "Statut";
      case TransactionFields.REFUSE_REASON:
        return "Raison du rejet SWAN";
      case TransactionFields.TRANSACTION_FAILED_REASON:
        return "Raison du rejet IMPACT";
      case TransactionFields.AMOUNT:
        return "Montant (TTC)";
      case TransactionFields.AMOUNT_BEFORE_TAXES:
        return "Montant Hors Taxes (HT)";
      case TransactionFields.AMOUNT_OF_TAXES:
        return "Montant TVA";
      case TransactionFields.IS_AMOUNT_MATCH:
        return "OCR";
      case TransactionFields.TYPE:
        return "Type";
      case TransactionFields.SERVICE_TYPE:
        return "Type de service";
      case TransactionFields.MERCHANT_NAME:
        return "Nom du commerçant";
      case TransactionFields.MERCHANT_CITY:
        return "Ville du commerçant";
      case TransactionFields.MERCHANT_COUNTRY:
        return "Pays du commerçant";
      case TransactionFields.MID:
        return "Identifiant du commerçant (MID)";
      case TransactionFields.CATEGORY_NAME:
        return "Nom de la catégorie";
      case TransactionFields.MCC:
        return "Merchant Category Code (MCC)";
      case TransactionFields.MCC_DESCRIPTION:
        return "Description du MCC";
      case TransactionFields.EXPENSE_TYPE:
        return "Type de dépense";
      case TransactionFields.IS_ROUND_TRIP:
        return "Trajet aller-retour";
      case TransactionFields.DISTANCE_KM_ESTIMATE:
        return "Estimation de la distance (km)";
      case TransactionFields.VEHICLE_KILOMETERS_AT_TRANSACTION:
        return "Kilomètrage du véhicule";
      case TransactionFields.QUANTITY:
        return "Quantité";
      case TransactionFields.REASON:
        return "Motif";
      case TransactionFields.INVOICE:
        return "Facture";
      case TransactionFields.NOPROOF:
        return "Pas de justificatif";
      case TransactionFields.CARBON_FOOTPRINT_ESTIMATE:
        return "Estimation de l'empreinte carbone (kg)";
      case TransactionFields.UPDATED_BY:
        return "Mis à jour par";
      case TransactionFields.UPDATED_AT:
        return "Mis à jour le";
      case TransactionFields.IS_UPDATABLE:
        return "Modifiable";
      case TransactionFields.PAYMENT_ID:
        return "ID de paiement";
      case TransactionFields.TRANSACTION_ID:
        return "ID de transaction";
      case TransactionFields.SWAN_TRANSACTION_SIDE:
        return "Débit / Crédit";
      case TransactionFields.CATEGORY_ACCOUNT:
        return "Compte Comptable";
      case TransactionFields.CARD:
        return "Carte";
      case TransactionFields.TIME_OF_PAYMENT:
        return "Heure du paiement";
      case TransactionFields.CARDGROUP:
        return "Groupe de carte";
      case TransactionFields.FUEL_TYPE:
        return "Type de carburant";
      case TransactionFields.FUEL_VOLUME_LITERS:
        return "Volume de carburant (L)";
      default:
        return "";
    }
  };
}

export enum TransactionFailedReasonEnum {
  CARD_TRANSACTION_THRESHOLD = "CARD_TRANSACTION_THRESHOLD",
  CARD_DAY_THRESHOLD = "CARD_DAY_THRESHOLD",
  CARD_MONTH_THRESHOLD = "CARD_MONTH_THRESHOLD",
  CARD_YEAR_THRESHOLD = "CARD_YEAR_THRESHOLD",
  MCC_UNREGISTERED = "MCC_UNREGISTERED",
  MID_UNREGISTERED = "MID_UNREGISTERED",
  CATEGORY_TRANSACTION_THRESHOLD = "CATEGORY_TRANSACTION_THRESHOLD",
  CATEGORY_DAY_THRESHOLD = "CATEGORY_DAY_THRESHOLD",
  CATEGORY_MONTH_THRESHOLD = "CATEGORY_MONTH_THRESHOLD",
  CATEGORY_YEAR_THRESHOLD = "CATEGORY_YEAR_THRESHOLD",
  CATEGORY_DAYS = "CATEGORY_DAYS",
  COUNTRY_TRANSACTION_THRESHOLD = "COUNTRY_TRANSACTION_THRESHOLD",
  COUNTRY_DAY_THRESHOLD = "COUNTRY_DAY_THRESHOLD",
  COUNTRY_MONTH_THRESHOLD = "COUNTRY_MONTH_THRESHOLD",
  COUNTRY_YEAR_THRESHOLD = "COUNTRY_YEAR_THRESHOLD",
  COUNTRY_DAYS = "COUNTRY_DAYS",
  GENERAL_RULE_TRANSACTION_THRESHOLD = "GENERAL_RULE_TRANSACTION_THRESHOLD",
  GENERAL_RULE_DAY_THRESHOLD = "GENERAL_RULE_DAY_THRESHOLD",
  GENERAL_RULE_MONTH_THRESHOLD = "GENERAL_RULE_MONTH_THRESHOLD",
  GENERAL_RULE_YEAR_THRESHOLD = "GENERAL_RULE_YEAR_THRESHOLD",
  GENERAL_RULE_DAYS = "GENERAL_RULE_DAYS",
  RESTRICTED = "RESTRICTED",
  UNKNOWN = "UNKNOWN",
  SWAN_REFUSED = "SWAN_REFUSED",
  BLACKLISTED_SERVICE = "BLACKLISTED_SERVICE",
  NO_RULE_FOR_CATEGORY = "NO_RULE_FOR_CATEGORY",
  COUNTRY_NOT_ALLOWED = "COUNTRY_NOT_ALLOWED",
  MAX_AMOUNT_OF_ACTION_REQUIRED_TRANSACTIONS_REACHED = "MAX_AMOUNT_OF_ACTION_REQUIRED_TRANSACTIONS_REACHED",
  ACCOUNT_CLOSED = "AccountClosed",
  ACCOUNT_HOLDER_DECEASED = "AccountHolderDeceased",
  ACCOUNT_LIMITED = "AccountLimited",
  ACCOUNT_SUSPENDED = "AccountSuspended",
  ACCOUNT_UNKNOWN = "AccountUnknown",
  BANK_REFUSED = "BankRefused",
  BENEFICIARY_ACCOUNT_BLOCKED = "BeneficiaryAccountBlocked",
  BENEFICIARY_ACCOUNT_CLOSED = "BeneficiaryAccountClosed",
  BENEFICIARY_ACCOUNT_INCORRECT = "BeneficiaryAccountIncorrect",
  BENEFICIARY_ACCOUNT_UNKNOWN = "BeneficiaryAccountUnknown",
  BENEFICIARY_BANK_BIC_INVALID = "BeneficiaryBankBicInvalid",
  BENEFICIARY_BRANCH_CODE_INVALID = "BeneficiaryBranchCodeInvalid",
  BENEFICIARY_PHONE_NUMBER_INVALID = "BeneficiaryPhoneNumberInvalid",
  BENEFICIARY_ID_NUMBER_INVALID = "BeneficiaryIdNumberInvalid",
  BENEFICIARY_RUT_NUMBER_INVALID = "BeneficiaryRutNumberInvalid",
  BENEFICIARY_TAX_ID_INVALID = "BeneficiaryTaxIdInvalid",
  BENEFICIARY_TAX_ID_SUSPENDED = "BeneficiaryTaxIdSuspended",
  BENEFICIARY_CURRENCY_INVALID = "BeneficiaryCurrencyInvalid",
  BENEFICIARY_BANK_NOT_REACHABLE = "BeneficiaryBankNotReachable",
  BENEFICIARY_DECEASED = "BeneficiaryDeceased",
  CARD_EXPIRED = "CardExpired",
  CARD_NOT_ACTIVATED = "CardNotActivated",
  CARD_PERMANENTLY_BLOCKED = "CardPermanentlyBlocked",
  CARD_SUSPENDED = "CardSuspended",
  CHECK_INVALID = "CheckInvalid",
  CHECK_NUMBER_INVALID = "CheckNumberInvalid",
  CREDITOR_BANK_OFFLINE = "CreditorBankOffline",
  CREDITOR_BANK_TECHNICAL_ERROR_OCCURRED = "CreditorBankTechnicalErrorOccurred",
  CREDITOR_BANK_TIMEOUT = "CreditorBankTimeout",
  DEBTOR_ACCOUNT_BLOCKED = "DebtorAccountBlocked",
  DEBTOR_ACCOUNT_CLOSED = "DebtorAccountClosed",
  DEBTOR_ACCOUNT_CONSUMER = "DebtorAccountConsumer",
  DEBTOR_ACCOUNT_UNKNOWN = "DebtorAccountUnknown",
  DEBTOR_BANK_OFFLINE = "DebtorBankOffline",
  DEBTOR_BANK_TECHNICAL_ERROR_OCCURRED = "DebtorBankTechnicalErrorOccurred",
  DEBTOR_BANK_TIMEOUT = "DebtorBankTimeout",
  DEBTOR_DECEASED = "DebtorDeceased",
  FUNDS_ALREADY_TRANSFERRED_BACK = "FundsAlreadyTransferredBack",
  INSUFFICIENT_FUNDS = "InsufficientFunds",
  INVALID_CREDITOR_NAME = "InvalidCreditorName",
  INVALID_EXPIRATION_DATE = "InvalidExpirationDate",
  INVALID_PIN = "InvalidPin",
  INVALID_PIN_ATTEMPTS_EXCEEDED = "InvalidPinAttemptsExceeded",
  INVALID_SECURITY_NUMBER = "InvalidSecurityNumber",
  LEGAL_OR_BANK_DECISION = "LegalOrBankDecision",
  MANDATE_INVALID = "MandateInvalid",
  MERCHANT_SHOULD_RESUBMIT_AUTHORIZATION = "MerchantShouldResubmitAuthorization",
  NO_ANSWER_FROM_BENEFICIARY = "NoAnswerFromBeneficiary",
  NO_ORIGINAL_TRANSACTION_RECEIVED = "NoOriginalTransactionReceived",
  PARTNER_REFUSED = "PartnerRefused",
  PARTNER_TECHNICAL_ERROR_OCCURRED = "PartnerTechnicalErrorOccurred",
  PERIOD_AMOUNT_LIMIT_EXCEEDED = "PeriodAmountLimitExceeded",
  PERIOD_NB_TRANSACTION_LIMIT_EXCEEDED = "PeriodNbTransactionLimitExceeded",
  PIN_REQUIRED_FOR_FURTHER_TRANSACTION = "PinRequiredForFurtherTransaction",
  REASON_NOT_SPECIFIED_BY_BANK = "ReasonNotSpecifiedByBank",
  REASON_NOT_SPECIFIED_BY_BENEFICIARY = "ReasonNotSpecifiedByBeneficiary",
  REASON_NOT_SPECIFIED_BY_DEBTOR = "ReasonNotSpecifiedByDebtor",
  REASON_NOT_SPECIFIED_BY_ORIGINATOR = "ReasonNotSpecifiedByOriginator",
  RECALL_ACCEPTED = "RecallAccepted",
  REFUND_REQUESTED_BY_DEBTOR = "RefundRequestedByDebtor",
  REFUND_REQUESTED_BY_CREDITOR = "RefundRequestedByCreditor",
  REGULATORY_REASON = "RegulatoryReason",
  RLMC_KEY_INVALID = "RlmcKeyInvalid",
  SWAN_OFFLINE = "SwanOffline",
  SWAN_TECHNICAL_ERROR_OCCURRED = "SwanTechnicalErrorOccurred",
  SWAN_TIMEOUT = "SwanTimeout",
  TECHNICAL_ISSUE = "TechnicalIssue",
  TERMS_AND_CONDITIONS_LIMIT_EXCEEDED = "TermsAndConditionsLimitExceeded",
  TRANSACTION_AMOUNT_INCORRECT = "TransactionAmountIncorrect",
  TRANSACTION_AMOUNT_LIMIT_EXCEEDED = "TransactionAmountLimitExceeded",
  TARGET_CURRENCY_NOT_AVAILABLE = "TargetCurrencyNotAvailable",
  TRANSACTION_DUPLICATED = "TransactionDuplicated",
  TRANSACTION_ON_ACCOUNT_TYPE_NOT_ALLOWED = "TransactionOnAccountTypeNotAllowed",
  TRANSACTION_ON_CARD_TYPE_NOT_ALLOWED = "TransactionOnCardTypeNotAllowed",
  TRANSACTION_PURPOSE_INVALID = "TransactionPurposeInvalid",
  TRANSACTION_REFERENCE_INVALID = "TransactionReferenceInvalid",
  TRANSACTION_TYPE_NOT_ALLOWED = "TransactionTypeNotAllowed",
  SWAN_REFUSED_REASON = "motifRefusSwan",
  IMPACT_REFUSED_REASON = "motifRefusImpact",
}

export namespace TransactionFailedReasonEnum {
  export const label = (reason: TransactionFailedReasonEnum): string => {
    switch (reason) {
      case TransactionFailedReasonEnum.CARD_TRANSACTION_THRESHOLD:
        return "Seuil par paiement atteint";
      case TransactionFailedReasonEnum.SWAN_REFUSED_REASON:
        return "Seuil par paiement atteint";
      case TransactionFailedReasonEnum.CARD_DAY_THRESHOLD:
        return "Seuil journalier atteint";
      case TransactionFailedReasonEnum.CARD_MONTH_THRESHOLD:
        return "Seuil mensuel atteint";
      case TransactionFailedReasonEnum.CARD_YEAR_THRESHOLD:
        return "Seuil annuel atteint";
      case TransactionFailedReasonEnum.MCC_UNREGISTERED:
        return "Catégorie non autorisée";
      case TransactionFailedReasonEnum.MID_UNREGISTERED:
        return "Identifiant MID non enregistré";
      case TransactionFailedReasonEnum.CATEGORY_TRANSACTION_THRESHOLD:
        return "Seuil par paiement de la catégorie atteint";
      case TransactionFailedReasonEnum.CATEGORY_DAY_THRESHOLD:
        return "Seuil journalier de la catégorie atteint";
      case TransactionFailedReasonEnum.CATEGORY_MONTH_THRESHOLD:
        return "Seuil mensuel de la catégorie atteint";
      case TransactionFailedReasonEnum.CATEGORY_YEAR_THRESHOLD:
        return "Seuil annuel de la catégorie atteint";
      case TransactionFailedReasonEnum.CATEGORY_DAYS:
        return "La règle sur cette catégorie n'autorise pas de paiement pour ce jour";
      case TransactionFailedReasonEnum.COUNTRY_TRANSACTION_THRESHOLD:
        return "Seuil par paiement du pays atteint";
      case TransactionFailedReasonEnum.COUNTRY_DAY_THRESHOLD:
        return "La règle sur ce pays n'autorise pas de paiement pour ce jour";
      case TransactionFailedReasonEnum.COUNTRY_MONTH_THRESHOLD:
        return "Seuil mensuel par pays atteint";
      case TransactionFailedReasonEnum.COUNTRY_YEAR_THRESHOLD:
        return "Seuil annuel par pays atteint";
      case TransactionFailedReasonEnum.COUNTRY_DAYS:
        return "La règle sur ce pays n'autorise pas de paiement pour ce jour";
      case TransactionFailedReasonEnum.GENERAL_RULE_TRANSACTION_THRESHOLD:
        return "Seuil par paiement atteint";
      case TransactionFailedReasonEnum.GENERAL_RULE_DAY_THRESHOLD:
        return "Seuil journalier atteint";
      case TransactionFailedReasonEnum.GENERAL_RULE_MONTH_THRESHOLD:
        return "Seuil mensuel atteint";
      case TransactionFailedReasonEnum.GENERAL_RULE_YEAR_THRESHOLD:
        return "Seuil annuel atteint";
      case TransactionFailedReasonEnum.GENERAL_RULE_DAYS:
        return "La règle générale n'autorise pas de paiement pour ce jour";
      case TransactionFailedReasonEnum.RESTRICTED:
        return "Transaction restreinte";
      case TransactionFailedReasonEnum.UNKNOWN:
        return "Raison inconnue";
      case TransactionFailedReasonEnum.SWAN_REFUSED:
        return "Paiement rejeté par SWAN";
      case TransactionFailedReasonEnum.BLACKLISTED_SERVICE:
        return "Commerçant sur liste noire";
      case TransactionFailedReasonEnum.NO_RULE_FOR_CATEGORY:
        return "Catégorie non autorisée";
      case TransactionFailedReasonEnum.COUNTRY_NOT_ALLOWED:
        return "Pays non autorisé";
      case TransactionFailedReasonEnum.PARTNER_REFUSED:
        return "Le partenaire a refusé la transaction";
      case TransactionFailedReasonEnum.MAX_AMOUNT_OF_ACTION_REQUIRED_TRANSACTIONS_REACHED:
        return "Nombre de paiements à completer maximum dépassé";
      case TransactionFailedReasonEnum.ACCOUNT_CLOSED:
        return "SWAN: Compte fermé";
      case TransactionFailedReasonEnum.ACCOUNT_HOLDER_DECEASED:
        return "SWAN: Titulaire du compte décédé";
      case TransactionFailedReasonEnum.ACCOUNT_LIMITED:
        return "SWAN: Compte limité";
      case TransactionFailedReasonEnum.ACCOUNT_SUSPENDED:
        return "SWAN: Compte suspendu";
      case TransactionFailedReasonEnum.ACCOUNT_UNKNOWN:
        return "SWAN: Compte inconnu";
      case TransactionFailedReasonEnum.BANK_REFUSED:
        return "SWAN: Refusé par la banque";
      case TransactionFailedReasonEnum.BENEFICIARY_ACCOUNT_BLOCKED:
        return "SWAN: Compte du bénéficiaire bloqué";
      case TransactionFailedReasonEnum.BENEFICIARY_ACCOUNT_CLOSED:
        return "SWAN: Compte du bénéficiaire fermé";
      case TransactionFailedReasonEnum.BENEFICIARY_ACCOUNT_INCORRECT:
        return "SWAN: Compte du bénéficiaire incorrect";
      case TransactionFailedReasonEnum.BENEFICIARY_ACCOUNT_UNKNOWN:
        return "SWAN: Compte du bénéficiaire inconnu";
      case TransactionFailedReasonEnum.BENEFICIARY_BANK_BIC_INVALID:
        return "SWAN: BIC de la banque du bénéficiaire invalide";
      case TransactionFailedReasonEnum.BENEFICIARY_BRANCH_CODE_INVALID:
        return "SWAN: Code de la succursale du bénéficiaire invalide";
      case TransactionFailedReasonEnum.BENEFICIARY_PHONE_NUMBER_INVALID:
        return "SWAN: Numéro de téléphone du bénéficiaire invalide";
      case TransactionFailedReasonEnum.BENEFICIARY_ID_NUMBER_INVALID:
        return "SWAN: Numéro d'identification du bénéficiaire invalide";
      case TransactionFailedReasonEnum.BENEFICIARY_RUT_NUMBER_INVALID:
        return "SWAN: Numéro RUT du bénéficiaire invalide";
      case TransactionFailedReasonEnum.BENEFICIARY_TAX_ID_INVALID:
        return "SWAN: Numéro d'identification fiscale du bénéficiaire invalide";
      case TransactionFailedReasonEnum.BENEFICIARY_TAX_ID_SUSPENDED:
        return "SWAN: Numéro d'identification fiscale du bénéficiaire suspendu";
      case TransactionFailedReasonEnum.BENEFICIARY_CURRENCY_INVALID:
        return "SWAN: Devise du bénéficiaire invalide";
      case TransactionFailedReasonEnum.BENEFICIARY_BANK_NOT_REACHABLE:
        return "SWAN: Banque du bénéficiaire non joignable";
      case TransactionFailedReasonEnum.BENEFICIARY_DECEASED:
        return "SWAN: Bénéficiaire décédé";
      case TransactionFailedReasonEnum.CARD_EXPIRED:
        return "SWAN: Carte expirée";
      case TransactionFailedReasonEnum.CARD_NOT_ACTIVATED:
        return "SWAN: Carte non activée";
      case TransactionFailedReasonEnum.CARD_PERMANENTLY_BLOCKED:
        return "SWAN: Carte bloquée de manière permanente";
      case TransactionFailedReasonEnum.CARD_SUSPENDED:
        return "SWAN: Carte suspendue";
      case TransactionFailedReasonEnum.CHECK_INVALID:
        return "SWAN: Chèque invalide";
      case TransactionFailedReasonEnum.CHECK_NUMBER_INVALID:
        return "SWAN: Numéro de chèque invalide";
      case TransactionFailedReasonEnum.CREDITOR_BANK_OFFLINE:
        return "SWAN: Banque du créancier hors ligne";
      case TransactionFailedReasonEnum.CREDITOR_BANK_TECHNICAL_ERROR_OCCURRED:
        return "SWAN: Erreur technique survenue à la banque du créancier";
      case TransactionFailedReasonEnum.CREDITOR_BANK_TIMEOUT:
        return "SWAN: Délai d'attente dépassé pour la banque du créancier";
      case TransactionFailedReasonEnum.DEBTOR_ACCOUNT_BLOCKED:
        return "SWAN: Compte du débiteur bloqué";
      case TransactionFailedReasonEnum.DEBTOR_ACCOUNT_CLOSED:
        return "SWAN: Compte du débiteur fermé";
      case TransactionFailedReasonEnum.DEBTOR_ACCOUNT_CONSUMER:
        return "SWAN: Compte du débiteur consommateur";
      case TransactionFailedReasonEnum.DEBTOR_ACCOUNT_UNKNOWN:
        return "SWAN: Compte du débiteur inconnu";
      case TransactionFailedReasonEnum.DEBTOR_BANK_OFFLINE:
        return "SWAN: Banque du débiteur hors ligne";
      case TransactionFailedReasonEnum.DEBTOR_BANK_TECHNICAL_ERROR_OCCURRED:
        return "SWAN: Erreur technique survenue à la banque du débiteur";
      case TransactionFailedReasonEnum.DEBTOR_BANK_TIMEOUT:
        return "SWAN: Délai d'attente dépassé pour la banque du débiteur";
      case TransactionFailedReasonEnum.DEBTOR_DECEASED:
        return "SWAN: Débiteur décédé";
      case TransactionFailedReasonEnum.FUNDS_ALREADY_TRANSFERRED_BACK:
        return "SWAN: Fonds déjà reversés";
      case TransactionFailedReasonEnum.INSUFFICIENT_FUNDS:
        return "SWAN: Fonds insuffisants";
      case TransactionFailedReasonEnum.INVALID_CREDITOR_NAME:
        return "SWAN: Nom du créancier invalide";
      case TransactionFailedReasonEnum.INVALID_EXPIRATION_DATE:
        return "SWAN: Date d'expiration invalide";
      case TransactionFailedReasonEnum.INVALID_PIN:
        return "SWAN: PIN invalide";
      case TransactionFailedReasonEnum.INVALID_PIN_ATTEMPTS_EXCEEDED:
        return "SWAN: Nombre de tentatives de PIN invalide dépassé";
      case TransactionFailedReasonEnum.INVALID_SECURITY_NUMBER:
        return "SWAN: Numéro de sécurité invalide";
      case TransactionFailedReasonEnum.LEGAL_OR_BANK_DECISION:
        return "SWAN: Décision légale ou bancaire";
      case TransactionFailedReasonEnum.MANDATE_INVALID:
        return "SWAN: Mandat invalide";
      case TransactionFailedReasonEnum.MERCHANT_SHOULD_RESUBMIT_AUTHORIZATION:
        return "SWAN: Le commerçant doit soumettre à nouveau l'autorisation";
      case TransactionFailedReasonEnum.NO_ANSWER_FROM_BENEFICIARY:
        return "SWAN: Pas de réponse du bénéficiaire";
      case TransactionFailedReasonEnum.NO_ORIGINAL_TRANSACTION_RECEIVED:
        return "SWAN: Aucune transaction originale reçue";
      case TransactionFailedReasonEnum.PARTNER_TECHNICAL_ERROR_OCCURRED:
        return "SWAN: Erreur technique survenue chez le partenaire";
      case TransactionFailedReasonEnum.PERIOD_AMOUNT_LIMIT_EXCEEDED:
        return "SWAN: Limite de montant pour la période dépassée";
      case TransactionFailedReasonEnum.PERIOD_NB_TRANSACTION_LIMIT_EXCEEDED:
        return "SWAN: Nombre de transactions pour la période dépassé";
      case TransactionFailedReasonEnum.PIN_REQUIRED_FOR_FURTHER_TRANSACTION:
        return "SWAN: PIN requis pour la transaction suivante";
      case TransactionFailedReasonEnum.REASON_NOT_SPECIFIED_BY_BANK:
        return "SWAN: Raison non spécifiée par la banque";
      case TransactionFailedReasonEnum.REASON_NOT_SPECIFIED_BY_BENEFICIARY:
        return "SWAN: Raison non spécifiée par le bénéficiaire";
      case TransactionFailedReasonEnum.REASON_NOT_SPECIFIED_BY_DEBTOR:
        return "SWAN: Raison non spécifiée par le débiteur";
      case TransactionFailedReasonEnum.REASON_NOT_SPECIFIED_BY_ORIGINATOR:
        return "SWAN: Raison non spécifiée par l'émetteur";
      case TransactionFailedReasonEnum.RECALL_ACCEPTED:
        return "SWAN: Rappel accepté";
      case TransactionFailedReasonEnum.REFUND_REQUESTED_BY_DEBTOR:
        return "SWAN: Remboursement demandé par le débiteur";
      case TransactionFailedReasonEnum.REFUND_REQUESTED_BY_CREDITOR:
        return "SWAN: Remboursement demandé par le créancier";
      case TransactionFailedReasonEnum.REGULATORY_REASON:
        return "SWAN: Raison réglementaire";
      case TransactionFailedReasonEnum.RLMC_KEY_INVALID:
        return "SWAN: Clé RLMC invalide";
      case TransactionFailedReasonEnum.SWAN_OFFLINE:
        return "SWAN: SWAN hors ligne";
      case TransactionFailedReasonEnum.SWAN_TECHNICAL_ERROR_OCCURRED:
        return "SWAN: Erreur technique survenue chez SWAN";
      case TransactionFailedReasonEnum.SWAN_TIMEOUT:
        return "SWAN: Délai d'attente dépassé chez SWAN";
      case TransactionFailedReasonEnum.TECHNICAL_ISSUE:
        return "SWAN: Problème technique";
      case TransactionFailedReasonEnum.TERMS_AND_CONDITIONS_LIMIT_EXCEEDED:
        return "SWAN: Limite des termes et conditions dépassée";
      case TransactionFailedReasonEnum.TRANSACTION_AMOUNT_INCORRECT:
        return "SWAN: Montant de la transaction incorrect";
      case TransactionFailedReasonEnum.TRANSACTION_AMOUNT_LIMIT_EXCEEDED:
        return "SWAN: Limite de montant de la transaction dépassée";
      case TransactionFailedReasonEnum.TARGET_CURRENCY_NOT_AVAILABLE:
        return "SWAN: Devise cible non disponible";
      case TransactionFailedReasonEnum.TRANSACTION_DUPLICATED:
        return "SWAN: Transaction dupliquée";
      case TransactionFailedReasonEnum.TRANSACTION_ON_ACCOUNT_TYPE_NOT_ALLOWED:
        return "SWAN: Transaction non autorisée pour ce type de compte";
      case TransactionFailedReasonEnum.TRANSACTION_ON_CARD_TYPE_NOT_ALLOWED:
        return "SWAN: Transaction non autorisée pour ce type de carte";
      case TransactionFailedReasonEnum.TRANSACTION_PURPOSE_INVALID:
        return "SWAN: Objet de la transaction invalide";
      case TransactionFailedReasonEnum.TRANSACTION_REFERENCE_INVALID:
        return "SWAN: Référence de la transaction invalide";
      case TransactionFailedReasonEnum.TRANSACTION_TYPE_NOT_ALLOWED:
        return "SWAN: Type de transaction non autorisé";
      default:
        return reason;
    }
  };
}

export interface TransactionClosePeriod {
  from: string;
  to: string;
}
export enum ExpenseType {
  TRIP = "TRIP",
  MILEAGE_ALLOWANCES = "MILEAGE_ALLOWANCES",
  SERVICE = "SERVICE",
  EXPENSE = "EXPENSE",
}

export enum TransactionType {
  CARD = "CARD",
  REIMBURSMENT = "REIMBURSMENT",
  OTHER = "OTHER",
}

export enum SwanStatusInfo {
  PENDING = "PENDING",
  BOOKED = "BOOKED",
  REJECTED = "REJECTED",
  NONE = "NONE",
}
