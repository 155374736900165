import axios, { AxiosRequestConfig } from "axios";
import { sessionQuery } from "../store/session/session.query";
import { sessionService } from "../store/session/session.service";
import { CategoriesFilter } from "@store/serviceCategories";
import { DashboardExpensesFilters } from "@store/dashboardExpenses/dashboardExpenses.models";
import { TransactionFilter } from "@store/transaction";
import { UserFilter } from "@store/users";
import { BankCardSettings } from "src/screens/auth/adminCompany/dashboard/createCardMass/CreateCardMass.modal";

const APIAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

APIAxios.interceptors.request.use(
  async (config) => {
    const token = sessionQuery.token;

    try {
      // @ts-expect-error
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  (err) => Promise.reject(err)
);

APIAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response.data.message === "TOKEN_EXPIRED" ||
      error.response.data.message === "DISABLED_ACCOUNT" ||
      error.response.data.message === "USER_DOES_NOT_EXIST"
    ) {
      await sessionService.logout();
    }
    return Promise.reject(error);
  }
);

export const APIRoutes = {
  /* --- ACCOUNT HOLDERS --- */
  GetAccountHolders: (): AxiosRequestConfig => ({
    method: "GET",
    url: "/users/account-holders",
  }),
  PatchBlockAccount: (accountHolderId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `companies/${accountHolderId}/activation`,
  }),
  setSuperAdminToHolder: (accountHolderId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/users/account-holder/${accountHolderId}`,
  }),
  resetAccountToSuperAdmin: (): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/users/account-holder/null`,
  }),
  updateCompanyName: (companyId: string, name: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/companies/${companyId}/name`,
    data: { name },
  }),
  /* --- SWAN --- */
  GETOnboarding: (): AxiosRequestConfig => ({
    method: "GET",
    url: "/swan/onboarding/client",
  }),
  GetAccountBalance: (): AxiosRequestConfig => ({
    method: "GET",
    url: "/swan/account-holder/balance",
  }),

  SuspendSepa: (sepaId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/swan/suspend-mandate/${sepaId}`,
  }),
  /* --- LOGIN --- */
  POSTLogin: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/login" }),
  GETPasswordRecovery: (email: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/auth/forgotten-password/${email}`,
    params: {
      email: email,
    },
  }),

  /* --- REGISTER --- */
  POSTRegisterEmployee: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/register-employee" }),
  POSTActivateAccount: (token: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/auth/activate-account/${token}`,
  }),
  POSTResendActivationMail: (email: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/auth/resend-activation-mail/${email}`,
  }),

  /* --- USERS --- */
  GETUser: (): AxiosRequestConfig => ({ method: "GET", url: "/users/profile" }),
  GETUsers: (
    page: number,
    orderBy?: string,
    orderByDirection?: "asc" | "desc",
    searchText?: string,
    noPagination?: boolean
  ): AxiosRequestConfig => ({
    method: "GET",
    url: "/users/paginated",
    params: {
      page: page,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      searchText: searchText,
      noPagination: noPagination,
    },
  }),
  DELETEUser: (userId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/users/${userId}`,
  }),

  /* --- COMPANY --- */

  GETCompany: (): AxiosRequestConfig => ({
    method: "GET",
    url: "/companies/own",
  }),
  GETCompanyExportTransaction: (): AxiosRequestConfig => ({
    method: "GET",
    url: `/companies/own/export-order`,
  }),
  PUTCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/companies/${companyId}`,
  }),
  GETCompanyTaxes: (companyId: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/companies/${companyId}/taxes`,
  }),
  POSTCompanyTaxes: (companyId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/companies/${companyId}/taxes`,
  }),
  PATCHCompanyTaxes: (companyId: string, taxId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/companies/${companyId}/taxes/${taxId}`,
  }),
  DELETECompanyTaxes: (companyId: string, taxId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/companies/${companyId}/taxes/${taxId}`,
  }),
  GETCompanySharedCardsSettings: (companyId: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/companies/${companyId}/settings/shared-cards`,
  }),
  PUTCompanySharedCardsSettings: (companyId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/companies/${companyId}/settings/shared-cards`,
  }),

  /* --- GLOBAL-SETTINGS --- */
  GETGlobalSettings: (): AxiosRequestConfig => ({
    method: "GET",
    url: "/global-settings",
  }),
  PUTCGU: (): AxiosRequestConfig => ({
    method: "PUT",
    url: "/global-settings/cgu",
  }),
  PUTHelpDocument: (): AxiosRequestConfig => ({
    method: "PUT",
    url: "/global-settings/help-url",
  }),
  PUTLogo: (): AxiosRequestConfig => ({
    method: "PUT",
    url: "/global-settings/logo",
  }),
  POSTFile: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/upload/public-file",
    headers: { "Content-Type": "multipart/form-data" },
  }),
  PUTCGUCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/companies/${companyId}/cgu`,
  }),
  PUTHelpDocumentForCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/companies/${companyId}/help-url`,
  }),
  PUTLogoCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/companies/${companyId}/logo`,
  }),
  GETFile: (key: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/upload/file/${key}`,
  }),

  /* --- CATEGORIES --- */
  POSTCategory: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/service-categories",
  }),
  PUTCategory: (id: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/service-categories/${id}`,
  }),
  GETCategories: (x: CategoriesFilter): AxiosRequestConfig => ({
    method: "GET",
    params: {
      page: x.page,
      orderBy: x.orderBy,
      orderByDirection: x.orderByDirection,
      searchText: x.searchText,
      fetchLogos: true,
    },
    url: "/service-categories/paginated",
  }),
  GETAllCategories: (images?: boolean): AxiosRequestConfig => ({
    method: "GET",
    url: "/service-categories",
    params: {
      fetchLogos: images,
    },
  }),
  DELETECategory: (id: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/service-categories/${id}`,
  }),

  /* --- CATEGORY CHILDREN --- */

  POSTCategoryChildren: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/service-categories/company-settings",
  }),
  PUTCategoryChildren: (id: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/service-categories/company-settings/${id}`,
  }),
  GETCategoryChildren: (
    page: number,
    orderBy?: string,
    orderByDirection?: "asc" | "desc",
    searchText?: string
  ): AxiosRequestConfig => ({
    method: "GET",
    params: {
      page: page,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      searchText: searchText,
    },
    url: "/service-categories/company-settings/paginated",
  }),
  DELETECategoryChildren: (id: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/service-categories/company-settings/${id}`,
  }),

  /* --- MID --- */
  GETMIDExceptions: (
    page: number,
    orderBy?: string,
    orderByDirection?: "asc" | "desc",
    searchText?: string
  ): AxiosRequestConfig => ({
    method: "GET",
    params: {
      page: page,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      searchText: searchText,
    },
    url: "/mid-exceptions/paginated",
  }),
  POSTMIDException: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/mid-exceptions",
  }),
  POSTMIDExceptionRequest: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/mid-exceptions/requested",
  }),
  PUTMIDException: (id: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/mid-exceptions/${id}`,
  }),
  DELETEMIDException: (id: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/mid-exceptions/${id}`,
  }),

  /* --- PAYMENT CONTROL --- */

  GETPaymentRules: (
    page: number,
    groupId?: string,
    orderBy?: string,
    orderByDirection?: "asc" | "desc"
  ): AxiosRequestConfig => ({
    method: "GET",
    params: {
      groupId: groupId,
      page: page,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
    },
    url: "/payment-control-rule/paginated",
  }),
  POSTPaymentRules: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/payment-control-rule",
  }),
  PUTPaymentRule: (id: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/payment-control-rule/${id}`,
  }),
  DELETEPaymentRule: (id: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/payment-control-rule/${id}`,
  }),

  /* --- TRANSACTIONS --- */

  GETTransactions: (filters: TransactionFilter): AxiosRequestConfig => ({
    method: "GET",
    params: filters,
    url: "/transactions",
  }),
  GETTransactionById: (id: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/transactions/${id}`,
  }),
  DeleteTransactionById: (id: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/transactions/${id}`,
  }),
  GETTrabsactionTotals: (): AxiosRequestConfig => ({
    method: "GET",
    url: "transactions/totals",
  }),
  POSTTransaction: (id: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/transactions/${id}/complete`,
    params: {
      transactionId: id,
    },
  }),
  PATCHTransactionIsUpdatable: (id: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/transactions/${id}/is-updatable`,
    params: {
      transactionId: id,
    },
  }),
  POSTClosePeriod: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/transactions/close-period`,
  }),
  POSTInvoice: (transactionId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/upload/invoice/${transactionId}`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  POSTExportTransaction: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/transactions/export",
  }),
  POSTExportAccounting: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/transactions/export-accounting",
  }),

  /* --- SCORES --- */
  GETScore: (from: Date, to: Date): AxiosRequestConfig => ({
    method: "GET",
    url: `/transactions/distance-over-period/`,
    params: {
      from: from,
      to: to,
    },
  }),

  /* --- BLACKLIST --- */
  GETBlacklistedServices: (
    page: number,
    orderBy?: string,
    orderByDirection?: "asc" | "desc",
    searchText?: string
  ): AxiosRequestConfig => ({
    method: "GET",
    params: {
      page: page,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      searchText: searchText,
    },
    url: "/blacklisted-service/paginated",
  }),
  POSTBlacklistedService: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/blacklisted-service",
  }),
  PUTBlacklistedService: (id: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/blacklisted-service/${id}`,
  }),
  DELETEBlacklistedService: (id: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/blacklisted-service/${id}`,
  }),

  /* --- GROUPS --- */
  GETGroupsPaginated: (
    page: number,
    orderBy?: string,
    orderByDirection?: "asc" | "desc",
    searchText?: string
  ): AxiosRequestConfig => ({
    method: "GET",
    params: {
      page: page,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      searchText: searchText,
    },
    url: "/groups/paginated",
  }),
  GETGroups: (): AxiosRequestConfig => ({ method: "GET", url: "/groups" }),
  POSTGroup: (): AxiosRequestConfig => ({ method: "POST", url: "/groups" }),
  PUTGroup: (id: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/groups/${id}`,
  }),
  DELETEGroup: (id: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/groups/${id}`,
  }),
  ToggleCardFromGroup: (groupId: string, cardId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/groups/${groupId}/cards/${cardId}`,
  }),

  /*** DASHBOARD EXPENSES */
  GetDashboardExpenses: (filters: DashboardExpensesFilters): AxiosRequestConfig => ({
    method: "GET",
    params: filters,
    url: `transactions/expenses-over-period/`,
  }),

  GetMissingTransactions: (dateStart: Date, dateEnd: Date): AxiosRequestConfig => ({
    method: "GET",
    url: `swan/missing-transactions/`,
    params: { from: dateStart, to: dateEnd },
    responseType: "blob",
  }),

  /* --- COMPANIES --- */

  getCompaniesLight: (
    page: number,
    orderBy?: string,
    orderByDirection?: "asc" | "desc",
    searchText?: string
  ): AxiosRequestConfig => ({
    method: "GET",
    url: "/companies/open",
    params: {
      page: page,
      orderBy: orderBy,
      orderByDirection: orderByDirection,
      searchText: searchText,
    },
  }),

  GETCompanyUsers: (filters: UserFilter): AxiosRequestConfig => ({
    method: "GET",
    params: {
      page: filters.page,
      orderBy: filters.orderBy,
      orderByDirection: filters.orderByDirection,
      searchText: filters.searchText,
      hasNoAccountHolderId: filters.hasNoAccountHolderId,
      pageSize: filters.pageSize ?? 10,
    },
    url: `companies/${filters.companyId}/users`,
  }),

  /* --- SHARED CARDS --- */
  GETSharedCards: (): AxiosRequestConfig => ({
    method: "GET",
    url: "cards/shared",
  }),
  GETCurrentCard: (): AxiosRequestConfig => ({
    method: "GET",
    url: "cards/me/shared",
  }),

  POSTSelectCard: (cardId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `cards/${cardId}/shared`,
  }),

  DELETECardShared: (cardId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `cards/${cardId}/shared`,
  }),

  POSTCreateCardMass: (data: BankCardSettings, file: File): AxiosRequestConfig => {
    const formData = new FormData();
    formData.append("file", file);
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });

    return {
      method: "POST",
      url: `/cards/mass-create`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    };
  },
};

export default APIAxios;
