export const getCurrencySymbol = (currency: string): string => {
    switch (currency) {
        case "USD":
        return "$";
        case "EUR":
        return "€";
        case "GBP":
        return "£";
        default:
        return currency;
    }
}
